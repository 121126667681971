import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import WebDesign from "pages/WebDesign";
import PrivacyPolicy from "pages/TermsConditions";
import TermsConditions from "pages/PrivacyPolicy";
import BetaRedirect from "pages/BetaRedirect";
import Competitions from "pages/Competitions";
import McMasterBrockContest from "pages/McMasterBrockContest";
import MakeupCompetition from "pages/MakeupCompetition";

// Aaron Taylor-Johnson
import LookAlikeContest from "pages/LookAlikeContest";

const ProjectRoutes = () => {
  let element = useRoutes([
    // Existing routes
    { path: "/", element: <WebDesign /> },
    { path: "*", element: <NotFound /> },
    {
      path: "termsconditions",
      element: <TermsConditions />
    },
    {
      path: "privacypolicy",
      element: <PrivacyPolicy />
    },

    // Aaron Taylor-Johnson
    {
      path: "/lookalike",
      element: <LookAlikeContest />
    },

    {
      path: "/beta",
      element: <BetaRedirect />
    },
    {
      path: "/Competitions",
      element: <Competitions />
    },
    {
      path: "/McMasterBrock",
      element: <McMasterBrockContest />
    },
    {
      path: "makeup",
      element: <MakeupCompetition />
    },
  ]);

  return element;
};

export default ProjectRoutes;
