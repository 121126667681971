// src/pages/BetaRedirect/index.jsx
import React, { useEffect } from "react";

export default function BetaRedirect() {
  useEffect(() => {
    // When this component mounts, redirect to TestFlight
    window.location.href = "https://testflight.apple.com/join/BTTaWhm3";
  }, []);

  return (
    <div style={{ padding: "40px", color: "#fff" }}>
      <h1>Redirecting to TestFlight...</h1>
      <p>
        If you are not redirected automatically,{" "}
        <a
          href="https://testflight.apple.com/join/BTTaWhm3"
          style={{ textDecoration: "underline", color: "#ffbb00" }}
        >
          click here
        </a>
        .
      </p>
    </div>
  );
}
