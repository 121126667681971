import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header";
import Footer from "components/Footer";

const APPLE_APP_STORE_URL = "https://apps.apple.com/ca/app/pagent/id6670614673";
const APPLE_BUTTON_SVG = "/images/ApplePreorderButton.svg";

export default function LookAlikeContest() {
  const [scrolled, setScrolled] = useState(false);

  // Countdown Timer for Jan 18, 2025 at 4:00 PM EST (21:00 UTC)
  const targetDate = new Date("2025-01-18T21:00:00Z").getTime();
  const [timeLeft, setTimeLeft] = useState(targetDate - Date.now());

  useEffect(() => {
    // Sticky header on scroll
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);

    // Update countdown every second
    const timer = setInterval(() => {
      setTimeLeft(targetDate - Date.now());
    }, 1000);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(timer);
    };
  }, [targetDate]);

  const className = scrolled ? "scrolled" : "";

  // Calculate time remaining
  const hasExpired = timeLeft <= 0;
  let days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0;

  if (!hasExpired) {
    days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
    seconds = Math.floor((timeLeft / 1000) % 60);
  }

  return (
    <>
      <Helmet>
        <title>British Girl Makeup Contest</title>
        <meta
          name="description"
          content="British Girl Makeup Contest on Pagent"
        />
      </Helmet>

      <div className="flex w-full flex-col gap-[50px] bg-gradient3 min-h-screen pt-32">
        <Header className={className} />

        <div className="flex flex-col items-center gap-10 px-4">
          <div className="container-xs flex flex-col items-start w-full md:px-5">
            {/* Main box with #FFFFFF background */}
            <div className="flex flex-col gap-5 rounded-[24px] bg-[#FFFFFF] py-8 sm:py-5 w-full">
              <div className="flex flex-col items-center px-10 sm:px-5">

                {/* Image alone */}
                <img
                  src="/images/makeupcompetition1.png"
                  alt="British Girl Makeup Contest"
                  className="w-[500px] h-auto rounded mb-4"
                />

                {/* Subtitle in black */}
                <p className="text-center text-black text-lg font-bold mb-1">
                
                </p>

                {/* Steps to Participate */}
                <div className="mt-2 w-full p-4 rounded-md" style={{ color: "black" }}>
                  <h3 className="text-center text-xl mb-3 font-semibold">
                    To Compete or Vote:
                  </h3>
                  <div className="space-y-2 lead-6">
                    {/* Step 1 */}
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>1.</strong> Install Apple’s beta app tool TestFlight. <br />
                      <a
                        href="https://apps.apple.com/us/app/testflight/id899247664"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        <img
                          src="/images/testflighticon.png"
                          alt="TestFlight"
                          className="inline w-10 h-10 mr-2 align-middle"
                          style={{ cursor: "pointer" }}
                        />
                        Click HERE to Install
                      </a>
                    </p>

                    {/* Step 2 */}
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>2.</strong> Download Pagent on TestFlight. <br />
                      <a
                        href="https://testflight.apple.com/join/3bNkfMNT"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        <img
                          src="/images/pagenticon.png"
                          alt="Pagent"
                          className="inline w-10 h-10 mr-2 align-middle"
                          style={{ cursor: "pointer" }}
                        />
                        Click HERE to Download
                      </a>
                    </p>

                    {/* Step 3 */}
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>3.</strong> To compete, submit your video between 2PM-4PM EST.
                    </p>

                    {/* Step 4 */}
                    <p style={{ color: "#333333" }}>
                      <strong style={{ color: "black" }}>4.</strong> Vote for the winner between 4PM-11:30PM EST.
                    </p>

                    {/* Winner Announcement */}
                    <p style={{ color: "#333333" }}>
                      <br />
                      The winner will be announced at midnight via Instagram:{" "}
                      <a
                        href="https://instagram.com/pagent.app"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        <strong>@pagent.app</strong>
                        
                      </a>
                     
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Preorder Button */}
          <div className="flex flex-col items-center mt-6">
            <a
              href={APPLE_APP_STORE_URL}
              target="_blank"
              rel="noreferrer"
              aria-label="Pre-order on the App Store"
            >
              <img
                src={APPLE_BUTTON_SVG}
                alt="Pre-order on the App Store"
                style={{ maxWidth: "200px" }}
              />
            </a>
          </div>

          {/* Footer */}
          <Footer style={{ width: "100%" }} />
        </div>
      </div>
    </>
  );
}