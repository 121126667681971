import jsonp from "jsonp";
import { useState } from "react";
import { Heading } from "../../components";

// Example: You have an AppleButton.png file in /public/images or /src/assets, etc.
// Make sure the path is correct for your project structure.
const APPLE_BUTTON_SVG = "/images/ApplePreorderButton.svg";
const APPLE_APP_STORE_URL = "https://apps.apple.com/ca/app/pagent/id6670614673";

export default function FeaturesSection() {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!validatePhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid 10-digit phone number";
    }

    if (Object.keys(newErrors).length === 0) {
      // Construct the URL properly
      const url = `https://pagent.us13.list-manage.com/subscribe/post?u=d6bc3166380b1bb9bb8b8c56a&id=a3f56fb79a&f_id=00904fe1f0&EMAIL=${email}&PHONE=${phoneNumber}`;
      try {
        jsonp(url, { param: "c" }, (err, data) => {
          if (err) {
            console.error("Error:", err);
            setErrors({ submit: "An error occurred. Please try again later." });
          } else if (data.result === "error") {
            console.error("Mailchimp error:", data.msg);
            setErrors({ submit: data.msg });
          } else {
            console.log("Success:", data);
            setIsSubmitted(true);
            // Reset form fields
            setEmail("");
            setPhoneNumber("");
          }
        });
      } catch (error) {
        console.error("Caught error:", error);
        setErrors({
          submit: "An unexpected error occurred. Please try again later.",
        });
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="features-section relative">
      <div
        className="w-full bg-no-repeat bg-cover bg-center overflow-x-hidden pb-4"
        style={{
          backgroundImage: "url(/images/bg-img.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="lg:w-10/12 md:w-11/12 container mx-auto pt-[100px] sm:pt-[130px] md:pt-[130px] lg:pt-[130px] flex flex-col justify-center md:flex-col lg:flex-row min-h-full pb-[30px] md:pb-[30px] lg:pb-0 px-[15px] sm:px-0 md:px-0 lg:px-0">
          {/* Hero Image (Mobile First) */}
          <div className="flex justify-center items-center md:hidden">
            <img
              src="/images/about us mobile.png"
              className="w-[80%] md:w-[90%] lg:w-[80%] hero-section-image"
              alt="Hero Section"
            />
          </div>

          {/* Content Section */}
          <div className="flex flex-col justify-center md:items-start items-center text-center md:text-left lg:w-1/2 md:w-full">
            <Heading
              as="h2"
              className="leading-[34px] sm:leading-[34px] md:leading-[40px] lg:leading-[62px] text-[24px] sm:text-[24px] md:text-[30px] lg:text-[44px] text-custom-title"
            >
              <span
                style={{
                  fontWeight: 700,
                  backgroundClip: "text",
                }}
                className="bg-gradient text-gradient-color text-custom-subtitle"
              >
                Video & Live Competitions
              </span>
            </Heading>

            {/* Apple .png button */}
            <div className="mt-5">
              {/* 
                Link the SVG to the App Store page.
                Make sure your SVG is accessible at the given path.
              */}
              <a
                href={APPLE_APP_STORE_URL}
                target="_blank"
                rel="noreferrer"
                aria-label="Pre-order on the App Store"
              >
                <img
                  src={APPLE_BUTTON_SVG}
                  alt="Pre-order on the App Store"
                  style={{ maxWidth: "200px" }} // Adjust width as needed
                />
              </a>
            </div>

            {isSubmitted ? (
              <div
                style={{ fontWeight: 500 }}
                className="mt-[30px] sm:mt-[30px] md:mt-[30px] lg:mt-[50px] w-full max-w-[300px] bg-green-500 bg-opacity-80 text-white p-4 rounded-md"
              >
                Thank you for submitting! We'll be in touch soon.
              </div>
            ) : (
              <>
                {/* 
                  Styled text:
                  "Signup for the" in white,
                  "Android" in lime green, 
                  "version" in white again 
                */}
                <h3 className="mt-[30px] sm:mt-[30px] md:mt-[30px] lg:mt-[50px] text-xl font-semibold mb-4">
                  <span style={{ color: "#FFF" }}>Signup for the </span>
                  <span style={{ color: "lime" }}>Android</span>
                  <span style={{ color: "#FFF" }}> app</span>
                </h3>

                <form onSubmit={handleSubmit} className="w-full max-w-[300px]">
                  <div className="mb-4">
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      name="PHONE"
                      className="REQ_CSS"
                      id="mce-PHONE"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="Enter your phone number"
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  {errors.submit && (
                    <p className="text-red-500 text-sm mt-1 mb-2">
                      {errors.submit}
                    </p>
                  )}
                  <button
                    type="submit"
                    className="w-full px-7 py-3 text-white-0 bg-[#31343a] rounded-full h-[50px] sm:h-[40px] md:h-[50px] lg:h-[60px] text-custom-button"
                  >
                    Submit
                  </button>
                </form>
              </>
            )}

            {/* Scroll to see features */}
            <div className="mt-[30px] sm:mt-[60px] md:mt-[70px] lg:mt-[120px] text-white-0 text-sm flex items-center md:items-start gap-x-2 relative">
              <div>
                <img
                  src="/images/Scroll.png"
                  alt="Scroll"
                  className="w-full h-full"
                />
              </div>
              <button className="text-center md:text-left mt-2 md:mt-0 text-custom-scroll">
                Scroll to see features
              </button>
            </div>
          </div>

          {/* Desktop Image */}
          <div className="hidden md:absolute lg:relative bottom-0 right-0 md:right-[15px] sm:right-0 lg:right-0 md:flex lg:w-1/2 md:w-[60%] lg:pl-8 md:pl-0 lg:justify-end md:justify-end">
            <div className="relative bg-no-repeat h-full">
              <div className="w-full h-full flex justify-center lg:justify-center md:justify-end items-center">
                <img
                  src="/images/hero_section_v3.png"
                  className="w-full md:w-[50%] lg:w-[100%] h-auto max-w-[674.77px] max-h-[695px] object-contain"
                  alt="Hero Section"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
