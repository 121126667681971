import { Img } from "components";
import ChatMenu from "./ChatMenu";

const PagentisNotJustSocialMedia = () => {
  return (
    <div
      className="w-full bg-no-repeat py-[45px] sm:py-[45px] md:py-[60px] lg:py-[100px] xl:py-[130px] relative "
      style={{
        background: "url(/images/img_group_13.png)",
        backgroundSize: "cover",
      }}
    >
      <Img
        src="/images/yellow-vector.png"
        className={
          "absolute left-[15px] md:left-[37px] lg:left-[37px] sm:left-[37px] -bottom-[17px] sm:-bottom-[3rem] md:-bottom-[3rem] lg:-bottom-[3rem] w-8 xs:w-12 md:w-18 lg:w-24 z-20"
        }
      />
      <div className="overflow-x-hidden container lg:w-10/12 xl:w-10/12 md:w-11/12 mx-auto px-[15px] sm:px-0 md:px-0 lg:px-0">
        <div className="w-full text-center pb-[30px] sm:pb-[45px] md:pb-[45px] lg:pb-[75px] xl:pb-[75px]">
          <h2 className="text-[20px] sm:text-[24px] md:text-[30px] lg:text-[40px] xl:text-[44px] leading-[26px] sm:leading-[30px] md:leading-[34px] lg:leading-[40px] xl:leading-[50px] font-bold text-[#142c5c] mobile-heading">
            <span className="text-gradient-color">Pagent</span> is MORE than
            just a social media app
          </h2>
          <p className="text-[#72809D] mt-[15px] tracking-wider font-light text-sm mobile-paragraph">
            Compete in real time against opponents
          </p>
        </div>
        <div className="grid-change-ipad grid-set-large-device grid xl:grid lg:grid md:flex md:flex-col xl:grid-cols-[auto,362px] lg:grid-cols-[auto,250px] md:grid-cols-[auto,250px] grid-cols-1 gap-4">
          <div>
            <div className="grid md:grid-cols-[auto,240px] lg:grid-cols-[auto,305px] xl:grid-cols-[auto,305px] grid-cols-1 gap-[17px] large-device-img-1-div">
              <div className="full-with-details w-full sm:w-full md:w-full lg:w-[427px] xl:w-[427px] h-auto lg:h-[258px] xl:h-[258px] bg-[#FBC46A] shadow-cardShadow border-[2px] rounded-[40px] border-[#FFFFFF33] relative pt-[30px] pb-[30px] pl-[30px] pr-[130px] large-device-padding overflow-hidden">
                <div className="bg-[#E68B5E] w-[230px] h-[73px] absolute top-[-65px] left-[-85px] rotate-[130deg]"></div>
                <p className="m-0 text-[#071C45] text-[20px] leading-[33px] font-medium">
                  Take centerstage to showcase your talents and vote for your
                  favorite competitors. Badges and prestige await.
                </p>
                <div className="bottom-0 right-0 absolute">
                  <Img
                    src="/images/pagent-more-1-img.png"
                    alt="more 1 img"
                    className="w-[206px] h-[288px]"
                  />
                </div>
                <div className="bottom-0 left-[72px] absolute">
                  <Img
                    src="/images/layer-arrow.svg"
                    alt="layer arrow"
                    className="w-[73px] h-[39px]"
                  />
                </div>
              </div>
              <Img
                style={{ objectFit: "cover", borderRadius: "40px" }}
                src="/images/pop of red.png"
                alt="212"
                className="w-full full-with-details h-auto sm:h-[258px] md:h-[258px] lg:h-[258px] xl:h-[258px] image-size"
              />
            </div>
            <div className="grid md:grid-cols-[auto,240px] lg:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-[17px] mt-[17px]">
              <div className="grid grid-rows-2 gap-[17px]">
                <div className="bg-[#142c5c] full-with-details rounded-3xl flex w-full sm:w-full md:w-full lg:w-[356px] xl:w-[356px] overflow-hidden py-[50px] pl-[30px] pr-[65px] sm:pr-[90px] md:pr-[90px] padding-right-inner-large relative items-start flex-col">
                  <p className="text-[20px]">
                    Are you a singer? Prove you're the best in live battles
                    against opponents.
                  </p>
                  <div className="top-0 right-[30px] bottom-0 m-auto flex items-center absolute">
                    <Img
                      src="/images/pagent-web-2-img.png"
                      alt="more 1 img"
                      className="w-[40px] h-[163px]"
                    />
                  </div>
                </div>
                <div className="bg-black rounded-3xl full-with-details w-full padding-right-inner-large sm:w-full md:w-full sm:h-auto lg:w-[356px] xl:w-[356px] md:h-auto overflow-hidden py-[50px] sm:py-[30px] md:py-[30px] pl-[30px] pr-[65px] sm:pr-[90px] md:pr-[90px] relative flex items-start flex-col">
                  <div className="top-[35px] right-[30px] absolute">
                    <Img
                      src="/images/pagent-web-3-img.png"
                      alt="more 1 img"
                      className="w-[29px] h-[29px]"
                    />
                  </div>
                  <div className="left-0 bottom-0 absolute">
                    <Img
                      src="/images/pagent-web-4-img.png"
                      alt="more 1 img"
                      className="w-[139px] h-[139px]"
                    />
                  </div>
                  <p className="text-[20px]">
                    Have a killer sense of style? Let users decide how you stack
                    up against other fashionistas.
                  </p>
                </div>
              </div>
              <div className="relative w-full full-with-details sm:w-full md:w-full lg:w-[356px] xl:w-[356px] h-[435px] sm:h-auto md:h-auto overflow-hidden">
                <Img
                  style={{ objectFit: "cover", borderRadius: "24px" }}
                  src="/images/pagent-web-5-img.png"
                  alt=""
                  className={"w-full h-full"}
                />
                <div className="gradient-color-vs w-full h-full absolute top-0 bottom-0 left-0 right-0"></div>
                <div className="text-[24px] sm:text-[18px] md:text-[18px] lg:text-[24px] xl:text-[28px] absolute bottom-[30px] left-[15px] text-[#fff]">
                  Get Recognized for your Talent
                </div>
              </div>
            </div>
          </div>
          <div className="grid change-ipad-pro lg:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 flex-col gap-4 grid-cols-1">
            <div className="h-full w-full rounded-3xl">
              <ChatMenu />
            </div>
            <div
              className="sm:h-full w-full bg-no-repeat rounded-3xl h-60"
              style={{
                backgroundImage: "url(/images/Star.png)",
                backgroundSize: "cover",
                backgroundPosition: "100% 100%",
              }}
            >
              <div className="text-black rounded-3xl p-5 flex justify-center items-start flex-col">
                <h4
                  style={{ fontWeight: 500 }}
                  className="lg:text-[90px] xl:text-[75px] md:text-[75px] sm:text-[75px] text-[45px] font-semibold"
                >
                  500+
                </h4>
                <p className="text-[24px] sm:text-[24px] md:text-[24px] lg:text-[24px] xl:text-[20px] text-black font-[500]">
                  Prizes available for early winners
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagentisNotJustSocialMedia;
